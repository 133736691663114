/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

const documents = {
    "\n  mutation PromotionPayment_Mutation($input: PromotionPaymentInput!) {\n    promotionPayment(input: $input) {\n      result {\n        confirmationToken\n        transactionId\n      }\n    }\n  }\n": types.PromotionPayment_MutationDocument,
    "\n  mutation CreatePayment_Mutation($input: CreatePaymentInput!) {\n    createPayment(input: $input) {\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n": types.CreatePayment_MutationDocument,
    "\n          query Me {\n            me {\n              id\n              email\n              role\n              verified\n            }\n          }\n        ": types.MeDocument,
    "\n  fragment ClientOrderCard on Order {\n    id\n    total\n    status\n    driver {\n      email\n    }\n    customer {\n      email\n    }\n    restaurant {\n      name\n    }\n    items {\n      dish {\n        name\n        options {\n          name\n        }\n      }\n    }\n  }\n": types.ClientOrderCardFragmentDoc,
    "\n  fragment DriverCard_OrderFragment on Order {\n    id\n    total\n    status\n    driver {\n      email\n    }\n    customer {\n      email\n    }\n    restaurant {\n      id\n      address\n    }\n  }\n": types.DriverCard_OrderFragmentFragmentDoc,
    "\n  mutation SetDriverOrderStatus_Mutation($input: SetDriverOrderStatusInput!) {\n    setDriverOrderStatus(input: $input) {\n      order {\n        status\n      }\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n": types.SetDriverOrderStatus_MutationDocument,
    "\n  fragment OwnerCard_OrderFragment on Order {\n    id\n    total\n    status\n    driver {\n      email\n    }\n    customer {\n      email\n    }\n    items {\n      options {\n        name\n      }\n      dish {\n        name\n      }\n    }\n  }\n": types.OwnerCard_OrderFragmentFragmentDoc,
    "\n  mutation SetRestaurantOrderStatus_Mutation(\n    $input: SetRestaurantOrderStatusInput!\n  ) {\n    setRestaurantOrderStatus(input: $input) {\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n": types.SetRestaurantOrderStatus_MutationDocument,
    "\n  fragment Card_CategoryFragment on Category {\n    id\n    coverImage\n    name\n    slug\n  }\n": types.Card_CategoryFragmentFragmentDoc,
    "\n  fragment Card_Restaurant on Restaurant {\n    id\n    category {\n      name\n    }\n    coverImage\n    name\n    isPromoted\n  }\n": types.Card_RestaurantFragmentDoc,
    "\n  fragment RestaurantImageDescription on Restaurant {\n    address\n    category {\n      name\n    }\n    coverImage\n    name\n  }\n": types.RestaurantImageDescriptionFragmentDoc,
    "\n  fragment DishCardClient on Dish {\n    id\n    ...DishCardTitle\n    ...DishCardImage\n    price\n    options {\n      name\n      extra\n      choices {\n        name\n        extra\n      }\n    }\n  }\n": types.DishCardClientFragmentDoc,
    "\n  fragment DishCardImage on Dish {\n    name\n    photo\n  }\n": types.DishCardImageFragmentDoc,
    "\n  fragment DishCardOwner on Dish {\n    ...DishCardTitle\n    ...DishCardImage\n    price\n  }\n": types.DishCardOwnerFragmentDoc,
    "\n  fragment DishCardTitle on Dish {\n    name\n    description\n  }\n": types.DishCardTitleFragmentDoc,
    "\n  fragment DishCards on Query {\n    myRestaurant(input: $input) {\n      restaurant {\n        menu {\n          id\n          ...DishCardOwner\n        }\n      }\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n": types.DishCardsFragmentDoc,
    "\n  fragment CoreRestaurantFields on Restaurant {\n    id\n    address\n    category {\n      name\n    }\n    coverImage\n    isPromoted\n    name\n  }\n": types.CoreRestaurantFieldsFragmentDoc,
    "\n  query Me {\n    me {\n      id\n      email\n      role\n      verified\n    }\n  }\n": types.MeDocument,
    "\n  mutation CreateAccount($createAccountInput: CreateAccountInput!) {\n    createAccount(input: $createAccountInput) {\n      token\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n": types.CreateAccountDocument,
    "\n  mutation Login($loginInput: LoginInput!) {\n    login(input: $loginInput) {\n      error {\n        ... on Error {\n          message\n        }\n      }\n      token\n    }\n  }\n": types.LoginDocument,
    "\n  query RestaurantsByCategory($input: CategoryInput!) {\n    category(input: $input) {\n      category {\n        id\n        name\n      }\n      error {\n        ... on Error {\n          message\n        }\n      }\n      restaurants {\n        id\n        ...Card_Restaurant\n      }\n    }\n  }\n": types.RestaurantsByCategoryDocument,
    "\n  query GetClientOrders($input: GetOrdersInput!) {\n    getOrders(input: $input) {\n      orders {\n        id\n        status\n        ...ClientOrderCard\n      }\n    }\n  }\n": types.GetClientOrdersDocument,
    "\n  subscription ClientOrderUpdates($input: OrderUpdatesInput!) {\n    orderUpdates(input: $input) {\n      id\n      status\n      ...ClientOrderCard\n    }\n  }\n": types.ClientOrderUpdatesDocument,
    "\n  query GetRestaurant($input: RestaurantInput!) {\n    getRestaurant(input: $input) {\n      restaurant {\n        ...RestaurantImageDescription\n        menu {\n          id\n          ...DishCardClient\n        }\n      }\n    }\n  }\n": types.GetRestaurantDocument,
    "\n  mutation CreateOrder($input: CreateOrderInput!) {\n    createOrder(input: $input) {\n      order {\n        id\n      }\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n": types.CreateOrderDocument,
    "\n  query GetRestaurants($input: RestaurantsInput!) {\n    allCategories {\n      categories {\n        id\n        ...Card_CategoryFragment\n      }\n    }\n    getRestaurants(input: $input) {\n      totalPages\n      restaurants {\n        id\n        ...Card_Restaurant\n      }\n    }\n  }\n": types.GetRestaurantsDocument,
    "\n  query SearchRestaurant($input: SearchRestaurantInput!) {\n    searchRestaurant(input: $input) {\n      restaurants {\n        ...CoreRestaurantFields\n      }\n      totalPages\n      totalResults\n    }\n  }\n": types.SearchRestaurantDocument,
    "\n  query GetOrders_Query($input: GetOrdersInput!) {\n    getOrders(input: $input) {\n      orders {\n        id\n        restaurant {\n          address\n          coords {\n            latitude\n            longitude\n          }\n          name\n        }\n        status\n      }\n    }\n  }\n": types.GetOrders_QueryDocument,
    "\n  mutation AcceptOrder_Mutation($input: AcceptOrderInput!) {\n    acceptOrder(input: $input) {\n      order {\n        id\n      }\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n": types.AcceptOrder_MutationDocument,
    "\n  subscription CookedOrders_Subscription {\n    cookedOrders {\n      id\n      restaurant {\n        address\n        coords {\n          latitude\n          longitude\n        }\n        name\n      }\n      status\n    }\n  }\n": types.CookedOrders_SubscriptionDocument,
    "\n  query DriverOrder_Query($input: GetOrderInput!) {\n    getDriverOrder(input: $input) {\n      order {\n        ...DriverCard_OrderFragment\n        customer {\n          coords {\n            latitude\n            longitude\n          }\n        }\n        restaurant {\n          coords {\n            latitude\n            longitude\n          }\n        }\n        status\n      }\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n": types.DriverOrder_QueryDocument,
    "\n  mutation CreateDish($input: CreateDishInput!) {\n    createDish(input: $input) {\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n": types.CreateDishDocument,
    "\n  mutation CreateRestaurant($input: CreateRestaurantInput!) {\n    createRestaurant(input: $input) {\n      restaurant {\n        id\n      }\n    }\n  }\n": types.CreateRestaurantDocument,
    "\n  query GetCategories_Query {\n    allCategories {\n      categories {\n        id\n        name\n      }\n    }\n  }\n": types.GetCategories_QueryDocument,
    "\n  query MyRestaurant($input: MyRestaurantInput!) {\n    myRestaurant(input: $input) {\n      restaurant {\n        name\n        ...RestaurantImageDescription\n      }\n    }\n    ...DishCards\n  }\n": types.MyRestaurantDocument,
    "\n  query MyRestaurants {\n    myRestaurants {\n      restaurants {\n        id\n        ...Card_Restaurant\n      }\n    }\n  }\n": types.MyRestaurantsDocument,
    "\n  query GetRestaurantOrders_Query($input: GetRestaurantOrdersInput!) {\n    getRestaurantOrders(input: $input) {\n      orders {\n        id\n        ...OwnerCard_OrderFragment\n      }\n    }\n  }\n": types.GetRestaurantOrders_QueryDocument,
    "\n  subscription OwnerOrderUpdates($input: OrderUpdatesInput!) {\n    orderUpdates(input: $input) {\n      id\n      status\n      ...OwnerCard_OrderFragment\n    }\n  }\n": types.OwnerOrderUpdatesDocument,
    "\n  subscription NewRestaurantOrder {\n    pendingOrders {\n      id\n      ...OwnerCard_OrderFragment\n    }\n  }\n": types.NewRestaurantOrderDocument,
    "\n  mutation VerifyEmail($input: VerifyEmailInput!) {\n    verifyEmail(input: $input) {\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n": types.VerifyEmailDocument,
    "\n            fragment VerifiedUser on User {\n              verified\n            }\n          ": types.VerifiedUserFragmentDoc,
    "\n  mutation EditProfile($input: EditProfileInput!) {\n    editProfile(input: $input) {\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n": types.EditProfileDocument,
    "\n              fragment EditedUser on User {\n                email\n                verified\n              }\n            ": types.EditedUserFragmentDoc,
};

export function graphql(source: "\n  mutation PromotionPayment_Mutation($input: PromotionPaymentInput!) {\n    promotionPayment(input: $input) {\n      result {\n        confirmationToken\n        transactionId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation PromotionPayment_Mutation($input: PromotionPaymentInput!) {\n    promotionPayment(input: $input) {\n      result {\n        confirmationToken\n        transactionId\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation CreatePayment_Mutation($input: CreatePaymentInput!) {\n    createPayment(input: $input) {\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreatePayment_Mutation($input: CreatePaymentInput!) {\n    createPayment(input: $input) {\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n          query Me {\n            me {\n              id\n              email\n              role\n              verified\n            }\n          }\n        "): (typeof documents)["\n          query Me {\n            me {\n              id\n              email\n              role\n              verified\n            }\n          }\n        "];
export function graphql(source: "\n  fragment ClientOrderCard on Order {\n    id\n    total\n    status\n    driver {\n      email\n    }\n    customer {\n      email\n    }\n    restaurant {\n      name\n    }\n    items {\n      dish {\n        name\n        options {\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment ClientOrderCard on Order {\n    id\n    total\n    status\n    driver {\n      email\n    }\n    customer {\n      email\n    }\n    restaurant {\n      name\n    }\n    items {\n      dish {\n        name\n        options {\n          name\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  fragment DriverCard_OrderFragment on Order {\n    id\n    total\n    status\n    driver {\n      email\n    }\n    customer {\n      email\n    }\n    restaurant {\n      id\n      address\n    }\n  }\n"): (typeof documents)["\n  fragment DriverCard_OrderFragment on Order {\n    id\n    total\n    status\n    driver {\n      email\n    }\n    customer {\n      email\n    }\n    restaurant {\n      id\n      address\n    }\n  }\n"];
export function graphql(source: "\n  mutation SetDriverOrderStatus_Mutation($input: SetDriverOrderStatusInput!) {\n    setDriverOrderStatus(input: $input) {\n      order {\n        status\n      }\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SetDriverOrderStatus_Mutation($input: SetDriverOrderStatusInput!) {\n    setDriverOrderStatus(input: $input) {\n      order {\n        status\n      }\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  fragment OwnerCard_OrderFragment on Order {\n    id\n    total\n    status\n    driver {\n      email\n    }\n    customer {\n      email\n    }\n    items {\n      options {\n        name\n      }\n      dish {\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment OwnerCard_OrderFragment on Order {\n    id\n    total\n    status\n    driver {\n      email\n    }\n    customer {\n      email\n    }\n    items {\n      options {\n        name\n      }\n      dish {\n        name\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation SetRestaurantOrderStatus_Mutation(\n    $input: SetRestaurantOrderStatusInput!\n  ) {\n    setRestaurantOrderStatus(input: $input) {\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SetRestaurantOrderStatus_Mutation(\n    $input: SetRestaurantOrderStatusInput!\n  ) {\n    setRestaurantOrderStatus(input: $input) {\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  fragment Card_CategoryFragment on Category {\n    id\n    coverImage\n    name\n    slug\n  }\n"): (typeof documents)["\n  fragment Card_CategoryFragment on Category {\n    id\n    coverImage\n    name\n    slug\n  }\n"];
export function graphql(source: "\n  fragment Card_Restaurant on Restaurant {\n    id\n    category {\n      name\n    }\n    coverImage\n    name\n    isPromoted\n  }\n"): (typeof documents)["\n  fragment Card_Restaurant on Restaurant {\n    id\n    category {\n      name\n    }\n    coverImage\n    name\n    isPromoted\n  }\n"];
export function graphql(source: "\n  fragment RestaurantImageDescription on Restaurant {\n    address\n    category {\n      name\n    }\n    coverImage\n    name\n  }\n"): (typeof documents)["\n  fragment RestaurantImageDescription on Restaurant {\n    address\n    category {\n      name\n    }\n    coverImage\n    name\n  }\n"];
export function graphql(source: "\n  fragment DishCardClient on Dish {\n    id\n    ...DishCardTitle\n    ...DishCardImage\n    price\n    options {\n      name\n      extra\n      choices {\n        name\n        extra\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment DishCardClient on Dish {\n    id\n    ...DishCardTitle\n    ...DishCardImage\n    price\n    options {\n      name\n      extra\n      choices {\n        name\n        extra\n      }\n    }\n  }\n"];
export function graphql(source: "\n  fragment DishCardImage on Dish {\n    name\n    photo\n  }\n"): (typeof documents)["\n  fragment DishCardImage on Dish {\n    name\n    photo\n  }\n"];
export function graphql(source: "\n  fragment DishCardOwner on Dish {\n    ...DishCardTitle\n    ...DishCardImage\n    price\n  }\n"): (typeof documents)["\n  fragment DishCardOwner on Dish {\n    ...DishCardTitle\n    ...DishCardImage\n    price\n  }\n"];
export function graphql(source: "\n  fragment DishCardTitle on Dish {\n    name\n    description\n  }\n"): (typeof documents)["\n  fragment DishCardTitle on Dish {\n    name\n    description\n  }\n"];
export function graphql(source: "\n  fragment DishCards on Query {\n    myRestaurant(input: $input) {\n      restaurant {\n        menu {\n          id\n          ...DishCardOwner\n        }\n      }\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment DishCards on Query {\n    myRestaurant(input: $input) {\n      restaurant {\n        menu {\n          id\n          ...DishCardOwner\n        }\n      }\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  fragment CoreRestaurantFields on Restaurant {\n    id\n    address\n    category {\n      name\n    }\n    coverImage\n    isPromoted\n    name\n  }\n"): (typeof documents)["\n  fragment CoreRestaurantFields on Restaurant {\n    id\n    address\n    category {\n      name\n    }\n    coverImage\n    isPromoted\n    name\n  }\n"];
export function graphql(source: "\n  query Me {\n    me {\n      id\n      email\n      role\n      verified\n    }\n  }\n"): (typeof documents)["\n  query Me {\n    me {\n      id\n      email\n      role\n      verified\n    }\n  }\n"];
export function graphql(source: "\n  mutation CreateAccount($createAccountInput: CreateAccountInput!) {\n    createAccount(input: $createAccountInput) {\n      token\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateAccount($createAccountInput: CreateAccountInput!) {\n    createAccount(input: $createAccountInput) {\n      token\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation Login($loginInput: LoginInput!) {\n    login(input: $loginInput) {\n      error {\n        ... on Error {\n          message\n        }\n      }\n      token\n    }\n  }\n"): (typeof documents)["\n  mutation Login($loginInput: LoginInput!) {\n    login(input: $loginInput) {\n      error {\n        ... on Error {\n          message\n        }\n      }\n      token\n    }\n  }\n"];
export function graphql(source: "\n  query RestaurantsByCategory($input: CategoryInput!) {\n    category(input: $input) {\n      category {\n        id\n        name\n      }\n      error {\n        ... on Error {\n          message\n        }\n      }\n      restaurants {\n        id\n        ...Card_Restaurant\n      }\n    }\n  }\n"): (typeof documents)["\n  query RestaurantsByCategory($input: CategoryInput!) {\n    category(input: $input) {\n      category {\n        id\n        name\n      }\n      error {\n        ... on Error {\n          message\n        }\n      }\n      restaurants {\n        id\n        ...Card_Restaurant\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetClientOrders($input: GetOrdersInput!) {\n    getOrders(input: $input) {\n      orders {\n        id\n        status\n        ...ClientOrderCard\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetClientOrders($input: GetOrdersInput!) {\n    getOrders(input: $input) {\n      orders {\n        id\n        status\n        ...ClientOrderCard\n      }\n    }\n  }\n"];
export function graphql(source: "\n  subscription ClientOrderUpdates($input: OrderUpdatesInput!) {\n    orderUpdates(input: $input) {\n      id\n      status\n      ...ClientOrderCard\n    }\n  }\n"): (typeof documents)["\n  subscription ClientOrderUpdates($input: OrderUpdatesInput!) {\n    orderUpdates(input: $input) {\n      id\n      status\n      ...ClientOrderCard\n    }\n  }\n"];
export function graphql(source: "\n  query GetRestaurant($input: RestaurantInput!) {\n    getRestaurant(input: $input) {\n      restaurant {\n        ...RestaurantImageDescription\n        menu {\n          id\n          ...DishCardClient\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetRestaurant($input: RestaurantInput!) {\n    getRestaurant(input: $input) {\n      restaurant {\n        ...RestaurantImageDescription\n        menu {\n          id\n          ...DishCardClient\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation CreateOrder($input: CreateOrderInput!) {\n    createOrder(input: $input) {\n      order {\n        id\n      }\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateOrder($input: CreateOrderInput!) {\n    createOrder(input: $input) {\n      order {\n        id\n      }\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetRestaurants($input: RestaurantsInput!) {\n    allCategories {\n      categories {\n        id\n        ...Card_CategoryFragment\n      }\n    }\n    getRestaurants(input: $input) {\n      totalPages\n      restaurants {\n        id\n        ...Card_Restaurant\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetRestaurants($input: RestaurantsInput!) {\n    allCategories {\n      categories {\n        id\n        ...Card_CategoryFragment\n      }\n    }\n    getRestaurants(input: $input) {\n      totalPages\n      restaurants {\n        id\n        ...Card_Restaurant\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query SearchRestaurant($input: SearchRestaurantInput!) {\n    searchRestaurant(input: $input) {\n      restaurants {\n        ...CoreRestaurantFields\n      }\n      totalPages\n      totalResults\n    }\n  }\n"): (typeof documents)["\n  query SearchRestaurant($input: SearchRestaurantInput!) {\n    searchRestaurant(input: $input) {\n      restaurants {\n        ...CoreRestaurantFields\n      }\n      totalPages\n      totalResults\n    }\n  }\n"];
export function graphql(source: "\n  query GetOrders_Query($input: GetOrdersInput!) {\n    getOrders(input: $input) {\n      orders {\n        id\n        restaurant {\n          address\n          coords {\n            latitude\n            longitude\n          }\n          name\n        }\n        status\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetOrders_Query($input: GetOrdersInput!) {\n    getOrders(input: $input) {\n      orders {\n        id\n        restaurant {\n          address\n          coords {\n            latitude\n            longitude\n          }\n          name\n        }\n        status\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation AcceptOrder_Mutation($input: AcceptOrderInput!) {\n    acceptOrder(input: $input) {\n      order {\n        id\n      }\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AcceptOrder_Mutation($input: AcceptOrderInput!) {\n    acceptOrder(input: $input) {\n      order {\n        id\n      }\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  subscription CookedOrders_Subscription {\n    cookedOrders {\n      id\n      restaurant {\n        address\n        coords {\n          latitude\n          longitude\n        }\n        name\n      }\n      status\n    }\n  }\n"): (typeof documents)["\n  subscription CookedOrders_Subscription {\n    cookedOrders {\n      id\n      restaurant {\n        address\n        coords {\n          latitude\n          longitude\n        }\n        name\n      }\n      status\n    }\n  }\n"];
export function graphql(source: "\n  query DriverOrder_Query($input: GetOrderInput!) {\n    getDriverOrder(input: $input) {\n      order {\n        ...DriverCard_OrderFragment\n        customer {\n          coords {\n            latitude\n            longitude\n          }\n        }\n        restaurant {\n          coords {\n            latitude\n            longitude\n          }\n        }\n        status\n      }\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query DriverOrder_Query($input: GetOrderInput!) {\n    getDriverOrder(input: $input) {\n      order {\n        ...DriverCard_OrderFragment\n        customer {\n          coords {\n            latitude\n            longitude\n          }\n        }\n        restaurant {\n          coords {\n            latitude\n            longitude\n          }\n        }\n        status\n      }\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation CreateDish($input: CreateDishInput!) {\n    createDish(input: $input) {\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateDish($input: CreateDishInput!) {\n    createDish(input: $input) {\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation CreateRestaurant($input: CreateRestaurantInput!) {\n    createRestaurant(input: $input) {\n      restaurant {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateRestaurant($input: CreateRestaurantInput!) {\n    createRestaurant(input: $input) {\n      restaurant {\n        id\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetCategories_Query {\n    allCategories {\n      categories {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCategories_Query {\n    allCategories {\n      categories {\n        id\n        name\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query MyRestaurant($input: MyRestaurantInput!) {\n    myRestaurant(input: $input) {\n      restaurant {\n        name\n        ...RestaurantImageDescription\n      }\n    }\n    ...DishCards\n  }\n"): (typeof documents)["\n  query MyRestaurant($input: MyRestaurantInput!) {\n    myRestaurant(input: $input) {\n      restaurant {\n        name\n        ...RestaurantImageDescription\n      }\n    }\n    ...DishCards\n  }\n"];
export function graphql(source: "\n  query MyRestaurants {\n    myRestaurants {\n      restaurants {\n        id\n        ...Card_Restaurant\n      }\n    }\n  }\n"): (typeof documents)["\n  query MyRestaurants {\n    myRestaurants {\n      restaurants {\n        id\n        ...Card_Restaurant\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetRestaurantOrders_Query($input: GetRestaurantOrdersInput!) {\n    getRestaurantOrders(input: $input) {\n      orders {\n        id\n        ...OwnerCard_OrderFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetRestaurantOrders_Query($input: GetRestaurantOrdersInput!) {\n    getRestaurantOrders(input: $input) {\n      orders {\n        id\n        ...OwnerCard_OrderFragment\n      }\n    }\n  }\n"];
export function graphql(source: "\n  subscription OwnerOrderUpdates($input: OrderUpdatesInput!) {\n    orderUpdates(input: $input) {\n      id\n      status\n      ...OwnerCard_OrderFragment\n    }\n  }\n"): (typeof documents)["\n  subscription OwnerOrderUpdates($input: OrderUpdatesInput!) {\n    orderUpdates(input: $input) {\n      id\n      status\n      ...OwnerCard_OrderFragment\n    }\n  }\n"];
export function graphql(source: "\n  subscription NewRestaurantOrder {\n    pendingOrders {\n      id\n      ...OwnerCard_OrderFragment\n    }\n  }\n"): (typeof documents)["\n  subscription NewRestaurantOrder {\n    pendingOrders {\n      id\n      ...OwnerCard_OrderFragment\n    }\n  }\n"];
export function graphql(source: "\n  mutation VerifyEmail($input: VerifyEmailInput!) {\n    verifyEmail(input: $input) {\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation VerifyEmail($input: VerifyEmailInput!) {\n    verifyEmail(input: $input) {\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n            fragment VerifiedUser on User {\n              verified\n            }\n          "): (typeof documents)["\n            fragment VerifiedUser on User {\n              verified\n            }\n          "];
export function graphql(source: "\n  mutation EditProfile($input: EditProfileInput!) {\n    editProfile(input: $input) {\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation EditProfile($input: EditProfileInput!) {\n    editProfile(input: $input) {\n      error {\n        ... on Error {\n          message\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n              fragment EditedUser on User {\n                email\n                verified\n              }\n            "): (typeof documents)["\n              fragment EditedUser on User {\n                email\n                verified\n              }\n            "];

export function graphql(source: string): unknown;
export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;